




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.addlectuer {
  .addlist {
    h3 {
      display: flex;
      border-left: 3px solid rgb(92, 107, 232);
      padding: 0;
      span {
        margin-left: 20px;
      }
      margin-bottom: 30px;
    }
    h3::before {
      content: "";
      border: 0;
    }
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 1000px;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 1000px;
  button {
    padding: 12px 30px;
  }
}
.tp {
  width: 50%;
  float: left;
  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }
  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;
    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}
.tip {
  position: relative;
  z-index: 100;
  // width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
}
.notify.el-notification.el-notification.left {
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  .el-notification__closeBtn {
    top: 10px;
  }
}
.imgCenter1 {
  img {
    object-fit: none !important;
  }
}
